/****************************************************************

	jQuery Extensions

*****************************************************************/


$(document).ready(function(e) {
    ribbon.init();

    $('#filter-recordings').bind('submit', function(e) {
        $('.mobile-view--filter').removeClass('is-visible');
        var url = $(this).find('input[name=sort]:checked').val();

        setTimeout(function() {
            window.location.href = url;
        }, 200);
        e.preventDefault();
    });

    $('.button--filter').bind('click', function(e) {
        $('.mobile-view--filter').addClass('is-visible');
        e.preventDefault();
    });

    $('.mobile-view--filter .close-button').bind('click', function(e) {
        $('.mobile-view--filter').removeClass('is-visible');
        e.preventDefault();
    });

    // $('.ribbon__cards__container').flickity({
    //     cellSelector: '.card',
    //     freeScroll: true,
    //     contain: true,
    //     pageDots: false,
    //     arrowShape: "M98.227452,92.2596045 C100.001321,94.0327347 100.001321,96.8970221 98.227452,98.6701523 C96.4535827,100.443283 93.5881014,100.443283 91.814232,98.6701523 L46.330402,53.2052739 C44.5565327,51.4321437 44.5565327,48.5678563 46.330402,46.7947261 L91.814232,1.32984769 C93.5881014,-0.443282564 96.4535827,-0.443282564 98.227452,1.32984769 C100.001321,3.10297795 100.001321,5.96726529 98.227452,7.74039554 L55.9274902,50.0227324 L98.227452,92.2596045 L98.227452,92.2596045 Z"
    // });

    $('.nav__subnav__container--right select').bind('change', function(e) {
        window.location.href = $('.nav__subnav__container--right select option:selected').attr('value');
    });
});

$(window).resize(function(e) {
    ribbon.setControls();
});


var ribbon = {
    init: function() {
        this.activateControls();
        this.setControls();
        $('.ribbon .ribbon__scroller').bind('scroll', function(e) {
            ribbon.setControls();
        });
    },
    activateControls: function() {
        $('.ribbon .ribbon__cards__controls--right .ribbon__cards__arrow').bind('click', function(e) {
            $(this).closest('.ribbon').find('.ribbon__scroller').scrollTo("+=276px", 400, {
                axis: 'x',
                //easing: 'easeOutExpo',
                complete: ribbon.setControls
            });
            e.preventDefault();
        });

        $('.ribbon .ribbon__cards__controls--left .ribbon__cards__arrow').bind('click', function(e) {
            $(this).closest('.ribbon').find('.ribbon__scroller').scrollTo("-=276px", 400, {
                axis: 'x',
                //easing: 'easeOutExpo',
                complete: ribbon.setControls
            });
            e.preventDefault();
        });
    },
    setControls: function() {
        $('.ribbon').each(function() {
            $scroller = $(this).find('.ribbon__scroller');
            $cards = $(this).find('.ribbon__cards__container');

            if($cards.width() > $scroller.width()) {
                $(this).find('.ribbon__cards__controls--right .ribbon__cards__arrow').removeClass('is-hidden');
            } else {
                $(this).find('.ribbon__cards__controls--right .ribbon__cards__arrow').addClass('is-hidden');
            }

             if(Math.round($scroller.scrollLeft() + $scroller.innerWidth()) >= $scroller[0].scrollWidth) {
                 $(this).find('.ribbon__cards__controls--right .ribbon__cards__arrow').addClass('is-hidden');
             }

            if($cards.offset().left < 0) {
                $(this).find('.ribbon__cards__controls--left .ribbon__cards__arrow').removeClass('is-hidden');
            } else {
                $(this).find('.ribbon__cards__controls--left .ribbon__cards__arrow').addClass('is-hidden');
            }

        });
    }
};
